import React from "react"

import WOW from 'wowjs';
import { Helmet } from "react-helmet"
import { findDOMNode } from "react-dom";
import * as yup from 'yup';

import UserContext from "../userContext.js"

import { Progress, TextArea } from 'semantic-ui-react'

import Footer from "../components/footer.jsx";

import Countdown from "react-countdown";

import LogoSlider from "../components/logoSlider.jsx";

import { Link } from "gatsby";

import { Dropdown, Checkbox } from 'semantic-ui-react'

import UserTopbar from "../components/userTopbar";

import ilustration4 from '../images/ilustration-4.png'

import upload from '../images/upload_clip.svg'
import editor from '../images/editor.svg'
import preview from '../images/clip_preview.svg'
import s1 from '../images/upload_s1.svg'
import s2 from '../images/upload_s2.svg'
import s3 from '../images/upload_s3.svg'
import mt3 from '../images/my_troop_3.png'

import add from '../images/add.svg'

import ImageUploading from 'react-images-uploading';


import c1 from '../images/clip-1.png'
import c2 from '../images/clip-2.png'
import c3 from '../images/clip-3.png'
import c4 from '../images/clip-4.png'
import c5 from '../images/clip-5.png'
import c6 from '../images/clip-6.png'

import filter from '../images/filter_icon.png'


import '../scss/clips.scss'
import 'semantic-ui-css/semantic.min.css'
import Selectable from "../components/selectable/index.jsx";
import SwitchButton from "../components/switchButton/index.jsx";

export default class myClips extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      player: false,
      step: 0,
      progress: 10,
      client: false,
    };
    this.references = {
      hero: React.createRef(),
      services: React.createRef(),
      numbers: React.createRef(),
      features: React.createRef(),
      logos: React.createRef(),
      contact: React.createRef(),
    }
  }

  componentDidUpdate() {
    if (this.state.step == 2 && this.state.progress < 100) {
      window.setTimeout(() => this.increment(), 100)
    }
  }

  componentDidMount() {
    this.setState({
      client: true,
    })
    if (window.location.href.indexOf("create") > -1) {
      this.setState({
        step: 1
      })
    }
  }

  increment = () => {
    this.setState({
      progress: this.state.progress + 10
    })
  }

  available_games = () => {
    return [
      { key: '1', value: '1', text: "CSGO" },
      { key: '2', value: '2', text: 'GTA V' },
      { key: '3', value: '3', text: 'Fortnite' },
      { key: '4', value: '4', text: 'Lol' },
    ]
  }

  visibilities = () => {
    return [
      { key: '1', value: '1', text: "Público" },
      { key: '2', value: '2', text: 'Privado' },
    ]
  }

  render() {
    return (
      <>
        {this.state.client &&
          <>
            <div className="clips" >
              <UserContext.Consumer>
                {userObject => (
                  <>
                    <UserTopbar onClick={() => this.setState({ player: true })} onClose={() => this.setState({ player: false })} player={this.state.player} user={userObject} profilePic={userObject?.userObject?.picture || null} />
                    <Helmet>
                    </Helmet>
                    <div className="wrapper app">

                      {this.state.step == 0 &&
                        <>
                          <section className="trooper--section">

                            <div className="linear--header">
                              <h3>
                                Mis clips
                              </h3>
                              <SwitchButton onClick={() => this.setState({ step: 1 })} text={'Subí tu clip'} icon={add} />
                            </div>
                            <p className="header--p">
                              Estos son los clips que subiste a Troop. Recordá que podés dejarlos
                              en privado o publicarlos para que los demás los vean.
                            </p>
                          </section>

                          <div className="clips--row">
                            <img onClick={() => this.setState({ player: true })} onDragStart={(e) => e.preventDefault()} src={c1} />
                            <img onClick={() => this.setState({ player: true })} onDragStart={(e) => e.preventDefault()} src={c2} />
                            <img onClick={() => this.setState({ player: true })} onDragStart={(e) => e.preventDefault()} src={c3} />
                            <img onClick={() => this.setState({ player: true })} onDragStart={(e) => e.preventDefault()} src={c4} />
                          </div>

                          <div className="clips--row">
                            <img onClick={() => this.setState({ player: true })} onDragStart={(e) => e.preventDefault()} src={c5} />
                            <img onClick={() => this.setState({ player: true })} onDragStart={(e) => e.preventDefault()} src={c6} />
                          </div>
                        </>
                      }

                      {this.state.step == 1 &&
                        <>
                          <section className="trooper--section">

                            <div className="linear--header">
                              <h3>
                                Subí tu clip a Troop
                              </h3>
                            </div>
                            <p className="header--p">
                              ¡Agregá tus mejores jugadas a tu perfil para que otros troopers
                              puedan verlas!
                            </p>
                          </section>

                          <ImageUploading onChange={() => this.setState({ step: 2 })} >
                            {({ imageList, onImageUpload, onImageRemoveAll }) => (
                              <img onClick={onImageUpload} onDragStart={(e) => e.preventDefault()} src={upload} />

                            )}
                          </ImageUploading>

                        </>
                      }

                      {this.state.step == 2 &&
                        <>
                          <section className="trooper--section">



                            <section className="trooper--section">

                              <div className="linear--header">
                                <h3>
                                  Seguí los pasos para subir el video.
                                </h3>
                              </div>
                              <img className="stepper--clip" onDragStart={(e) => e.preventDefault()} src={s1} />
                              <div className="uploader--container">
                                <div className="form--container">
                                  <form>
                                    <div className="dropdown--container">
                                      <Dropdown key="day" placeholder="¿Qué juego se ve en el clip?" fluid autocomplete='false' selection options={this.available_games()} />
                                    </div>
                                    <div className="upload--container">
                                      <p>Subiendo el archivo</p>
                                      <div className="progress--container">
                                        <Progress percent={this.state.progress} progress success />
                                      </div>
                                    </div>
                                    <div className="text--container">
                                      <p>Elegí un título para tu video (obligatorio)</p>
                                      <TextArea placeholder='Agregá un título que describa el video.' style={{ minHeight: 100 }} />
                                    </div>
                                    <div className="text--container">
                                      <p>Podés escribir una descripción (opcional)</p>
                                      <TextArea placeholder='Explicá un poco más sobre el video.' style={{ minHeight: 100 }} />
                                    </div>
                                  </form>

                                </div>
                                <img src={preview} />
                              </div>
                              <div className="button--container">
                                <button onClick={() => this.setState({ step: this.state.step + 1 })} className="button">Siguiente</button>
                              </div>
                            </section>
                          </section>


                        </>
                      }

                      {this.state.step == 3 &&
                        <>
                          <section className="trooper--section">



                            <section className="trooper--section">

                              <div className="linear--header">
                                <h3>
                                  Seguí los pasos para subir el video.
                                </h3>
                              </div>
                              <img className="stepper--clip" onDragStart={(e) => e.preventDefault()} src={s2} />
                              <img className="stepper--clip" onDragStart={(e) => e.preventDefault()} src={editor} />
                              <div className="button--container">
                                <button onClick={() => this.setState({ step: this.state.step + 1 })} className="button">Siguiente</button>
                              </div>
                            </section>
                          </section>

                        </>
                      }

                      {this.state.step == 4 &&
                        <>
                          <section className="trooper--section">



                            <section className="trooper--section">

                              <div className="linear--header">
                                <h3>
                                  Seguí los pasos para subir el video.
                                </h3>
                              </div>
                              <img className="stepper--clip" onDragStart={(e) => e.preventDefault()} src={s3} />
                              <h3>
                                ¡Tu video ya está listo!
                              </h3>
                              <p className="header--p">
                                Para terminar, te pedimos que elijas la visibilidad de tu video. Podes elegir
                                público para publicarlo en tu perfil y que todos lo vean, o privado para verlo
                                sólo vos y poder subirlo más adelante
                              </p>
                              <div className="dropdown--container short">
                                <Dropdown key="day" placeholder="Visibilidad" fluid autocomplete='false' selection options={this.visibilities()} />
                              </div>
                              <div className="button--container">
                                <button onClick={() => this.setState({ step: 0 })} className="button">Finalizar</button>
                              </div>
                            </section>
                          </section>

                        </>
                      }

                    </div>

                  </>
                )}




              </UserContext.Consumer>
            </div>
            <Footer />
          </>
        }
      </>
    );
  }
}
